@charset "utf-8";

#main .mv{
  background: url('../i/topimg_bg.jpg') no-repeat top center;
  text-align: center;
  
  h1{
    padding: 30px 0;
  }
  
  .lead{
    margin-top: 30px;
  }
}


.bazaar{
  margin:20px;
  font-size: 85%;
  border: 2px solid #274B89;

  h2{
    margin: 0 0 2em 0;
    font-size: 140%;
    text-align: center;
  }
  
  dl.summery{
    margin: 0em 0 0em 0;
    font-family: "ＭＳ ゴシック", "Osaka−等幅","ヒラギノ角ゴ Pro W3",monospace;
  
    dt{
      width: 8em;
      float: left;
      margin: 0 0 0.8em 0;
    }
    dd{
      margin: 0 0 0.8em 9em;
    }
  }
  
  .contact{
    border-top: 1px dotted #ccc;
    margin: 0.5em 0 0 0;
    padding: 0.5em 0 0 0;
    
    p{
      margin-bottom:1em;
    }
  }
}

/** インフォメーション */
#infomation{
  
  h3{
    margin:0 0 15px 0;
    color:#2e4a72;
    border-bottom:solid 2px #2e4a72;
  }
  
  ul{
    
    li{
      margin:0 0 0.8em 0;
    }
  }
}

p.info_li{
  padding:0 0 0 23px;
  font-size:80%;line-height:1.45;
  background: url("/common/i/info_li.gif") no-repeat left center;
}

p.date{
  border-top:dotted 1px #999;
  margin:4px 0 0 0;
  padding:2px 0 0 0;
  font-size:10px;
  text-align:right;
}